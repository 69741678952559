import { TeamDoc } from '@arcadehq/shared/types'
import pick from 'lodash/pick'
import { Team, TeamData } from 'src/types'

import { useFirebaseEntity } from './firebase'

const COLLECTION_NAME = 'teams'

export function getTeamFromDoc(data: TeamDoc): TeamData | undefined {
  return pick(data, [
    'name',
    'group',
    'logo',
    'watermark',
    'features',
    'currentSubscriber',
    'activeMemberCount',
    'prefs',
    'inviteByUrlId',
    'customerId',
    'trialEndDateIso',
    'trialUpgradeRequested',
    'slackChannelRequested',
    'sharePageLogo',
    'sharePageButton',
    'timezone',
    'lastMigrated',
    'isOverdue',
    'overdueDate',
    'colors',
  ])
}

export function getUpdateData(updates: Partial<TeamData>): Partial<TeamDoc> {
  return updates
}

export function useTeam(group?: string | null) {
  const { entity: team, loading } = useFirebaseEntity<Team, TeamData, TeamDoc>(
    COLLECTION_NAME,
    group ?? '',
    getTeamFromDoc,
    getUpdateData,
    false,
    !group
  )
  return { team, loading }
}
